import LoginForm from "../components/LoginForm";

export default function Login() {
  return (
    <>
      <div className="backdrop"></div>
      <LoginForm />
    </>
  );
}
